<template>
	<div id="datos-seguro">
		<v-form ref="form" v-model="valid" lazy-validation>
			<v-card>
				<v-card-text
					style="background: #eee; overflow-y: scroll; max-height: 72vh"
				>
					<v-row class="mt-2">
						<v-col cols="12" md="12" sm="12">
							<base-material-card color="primary" class="px-5 py-3">
								<template v-slot:heading>
									<div class="text-h5 font-weight-light my-2">
										Datos del Seguro
									</div>
								</template>
								<div
									class="text-h4 font-weight-light mx-2 my-2 red--text text-right"
								>
									<p class="blue--text font-weight-light mx-2 text-right">
										<b>
											ATENCIÓN: Los datos se guardarán al avanzar a la siguiente
											sección</b
										>
									</p>
								</div>
								<v-card-text>
									<v-row class="pt-0">
										<v-col cols="12" md="6" sm="12" class="py-0">
											<v-card outlined>
												<v-card-title
													class="primary text-white text-h3 text--accent-2 text-left"
												>
													<v-row wrap>
														<v-col cols="12" xs="6" md="6">
															Datos del Seguro
														</v-col>
														<v-col cols="12" xs="6" md="6">
															<v-switch
																dark
																@click="registrarSeguro()"
																label="Registrar"
																color="white"
																class="mt-0"
																hide-details
																v-model="seguroDisable"
															>
															</v-switch>
														</v-col>
													</v-row>
												</v-card-title>

												<v-card-text>
													<v-container>
														<v-row>
															<v-col cols="12" md="12" sm="12" class="py-0">
																<v-autocomplete
																	:items="itemsSN"
																	item-value="id"
																	item-text="nombre"
																	label="Solicita Cotización"
																	v-model="form.solicitaCotizacion"
																	:rules="
																		seguroDisable ? [$rulesRequerido] : []
																	"
																	:readonly="readonly"
																	:disabled="!seguroDisable"
																></v-autocomplete>
															</v-col>
															<v-col cols="12" md="12" sm="12" class="py-0">
																<v-autocomplete
																	:items="aseguradoras"
																	item-value="id"
																	item-text="nombre"
																	label="Compañia Aseguradora"
																	v-model="form.aseguradora_id"
																	:rules="
																		seguroDisable ? [$rulesRequerido] : []
																	"
																	:readonly="readonly"
																	:disabled="!seguroDisable"
																></v-autocomplete>
															</v-col>
															<v-col cols="12" md="12" sm="12" class="py-0">
																<v-autocomplete
																	:items="coberturas"
																	item-value="id"
																	item-text="nombre"
																	label="Tipo de Cobertura"
																	v-model="form.tipo_cobertura_id"
																	:rules="
																		seguroDisable ? [$rulesRequerido] : []
																	"
																	:readonly="readonly"
																	:disabled="!seguroDisable"
																></v-autocomplete>
															</v-col>
															<v-col cols="12" sm="4" class="py-0">
																<v-checkbox
																	class="mt-4"
																	v-model="form.poliza_en_tramite"
																	label="Poliza en tramite?"
																	@change="setPolizaEnTramite()"
																	filled
																	dense
																	:disabled="!seguroDisable"
																></v-checkbox>
															</v-col>
															<v-col cols="12" sm="4" class="py-0">
																<v-text-field
																	v-model="form.nro_poliza"
																	label="Número de Poliza"
																	:rules="
																		seguroDisable && nroPolizaRequerido
																			? [$rulesRequerido, $rulesAlfaNum]
																			: []
																	"
																	:readonly="readonly"
																	:disabled="
																		!seguroDisable || !nroPolizaRequerido
																	"
																	:hint="hintNroPoliza"
																	persistent-hint
																></v-text-field>
															</v-col>

															<v-col cols="12" sm="4" class="py-0">
																<vuetify-money
																	v-model="form.importe"
																	label="Importe"
																	v-bind:options="options"
																	clearable
																	:rules="
																		seguroDisable ? [$rulesFloatRequerido] : []
																	"
																	:readonly="readonly"
																	:disabled="!seguroDisable"
																/>
															</v-col>
														</v-row>
													</v-container>
												</v-card-text>
											</v-card>
										</v-col>
										<v-col cols="12" md="6" sm="12" class="py-0">
											<DomicilioForm
												ref="guardaHabitual"
												:item="form.guardaHabitual"
												title="Guarda Habitual"
												:key="keyGuardaHabitual"
												:index="form.index"
												:indexPadre="form.index"
												:showRegistrar="true"
												@resetItem="resetItem"
											></DomicilioForm>
										</v-col>
									</v-row>

									<v-row class="mt-2">
										<v-col cols="12" md="12" sm="12">
											<base-material-card color="primary" class="px-5 py-3">
												<template v-slot:heading>
													<div class="text-h5 font-weight-light my-2">
														Datos del Formulario GP-02
													</div>
												</template>

												<v-card-text class="py-6">
													<div
														class="text-h4 font-weight-light mx-0 mb-2 red--text text-center grey-lighten-2 pa-2"
													>
														Tenga en cuenta que una vez que carga un formulario
														se pasará a utilizado. Si no selecciona ninguno el
														tramite continuará normalmente.<br />
													</div>
													<v-row class="mx-0 justify-center mt-4" dense>
														<v-col
															cols="12"
															md="6"
															sm="12"
															:style="{
																borderRadius: '5px',
																backgroundColor: $cv('primary') + '20',
															}"
														>
															<v-row class="px-3 py-6">
																<v-col cols="12" md="6" sm="6" class="py-0">
																	<v-checkbox
																		class="mt-4"
																		v-model="form.con_formulario_gp02"
																		label="Dispone de Formulario GP-02?"
																		@change="conFormulario02()"
																		filled
																		dense
																	></v-checkbox>
																</v-col>
																<v-col cols="12" md="6" sm="6" class="py-0">
																	<v-text-field
																		v-model="form.formulario_gp02"
																		label="Formulario GP-02"
																		:disabled="formularioDisable"
																		:rules="
																			!formularioDisable
																				? [$rulesRequerido, $rulesAlfaNum]
																				: []
																		"
																	></v-text-field>
																</v-col>
															</v-row>
														</v-col>
													</v-row>
												</v-card-text>
											</base-material-card>
										</v-col>
									</v-row>
								</v-card-text>
							</base-material-card>
						</v-col>
					</v-row>

					<v-row>
						<v-col class="text-right" cols="12" md="12" sm="12">
							<v-btn color="red" @click="$exit(idTramite)">Salir</v-btn>
							<v-btn color="primary-2" @click="back()">Atras</v-btn>
							<v-btn color="primary" @click="next()">Siguiente</v-btn>
						</v-col>
					</v-row>
					<br />
				</v-card-text>
			</v-card>
		</v-form>
		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<style scoped>
.right-input >>> input {
	text-align: right;
}
.chip-indicadores {
	color: "blue-grey--text darken-4";
	font-size: 14px;
	/*font-family: 'Material Icons';*/
	/*font-weight: bold;*/
	font-style: normal;
}

.primary-change {
	color: #fff !important;
	/*background-color: #a7a8a8 !important;*/
	background-color: #105cfa !important;
	font-size: 20px;
	font-weight: bold;
	height: 70px !important;
	display: flex;
	align-items: center;
}
.col-f12 {
	text-align: center;
	font-size: 2rem;
	/*border-radius: 28px;*/
	font-style: #a0a0a0 !important;
	color: #a0a0a0 !important;
	font-weight: 500 !important;
	border-bottom: solid;
	border-color: #011f5b;
	/*border-radius: 28px;*/
	/*background: #eee;*/
	padding: 8px 0px 23px 17px;
	height: 55px;
	margin-top: 7px;
	margin-left: 7px !important;
	margin-right: 15px;
}
.centered-input >>> input {
	text-align: center;
	font-size: 1.5rem;
	color: #105cfa !important;
	margin-top: 16px;
	border-bottom: solid;
	border-color: #011f5b;
}
</style>
<script>
	import DomicilioForm from "../../personas/components/DomicilioForm";

	export default {
		name: "DatosSeguro",
		components: {
			DomicilioForm,
		},
		props: {
			seguro: { Object, default: null },
			tipoF01: { String, default: null },
			idTramite: { Number, default: null },
			readonly: { type: Boolean, default: false },
		},
		data: () => ({
			mask: "##/##/####",
			keyGuardaHabitual: 0,
			options: {
				locale: "es-AR",
				prefix: "$",
				suffix: "",
				length: 11,
				precision: 2,
			},

			snackbar: false,
			color: "success",
			timeout: 4000,
			text: "Se ha registrado una nueva solicitud",
			itemsSN: [
				{ id: "S", nombre: "SI" },
				{ id: "N", nombre: "NO" },
			],
			aseguradoras: [
				/*{ id: "1", nombre: "LA CAJA" },
							      { id: "2", nombre: "FEDERACION PATRONAL" },
							      { id: "3", nombre: "SANCOR SEGUROS" },*/
			],
			coberturas: [
				{ id: "1", nombre: "Todo Riesgo" },
				{ id: "2", nombre: "Todo Riesgo Franquicia 5%" },
				{ id: "3", nombre: "Tercero completo" },
				{ id: "4", nombre: "Resposabilidad Civil" },
			],
			seguroDisable: false,
			guardaHabitualDisable: false,
			nroPolizaRequerido: true,
			valid: true,

			form: {
				guardaHabitual: {
					calle: "",
					numero: "",
					piso: "",
					depto: "",
					provincia: "",
					localidad: "",
					barrio: "",
					cp: "",
					partido: "",
				},
			},
			formularioDisable: true,
			hintNroPoliza: "",
		}),

		methods: {
			back() {
				this.$emit("send-message", "4");
			},
			conFormulario02() {
				console.log(this.form.con_formulario_gp02);
				if (this.form.con_formulario_gp02) {
					this.formularioDisable = false;
					this.form.formulario_gp02 = this.form.formulario_gp02.length
						? this.form.formulario_gp02
						: "";
				} else {
					this.formularioDisable = true;
					this.form.formulario_gp02 = "";
				}
				//this.formularioDisable = !this.formularioDisable;
				//console.log(this.formularioDisable);
			},
			registrarSeguro() {
				//this.seguroDisable = !this.seguroDisable;
				if (!this.seguroDisable) {
					this.form.solicitaCotizacion = "";
					this.form.aseguradora_id = "";
					this.form.tipo_cobertura_id = "";
					this.form.poliza_en_tramite = "";
					this.form.nro_poliza = "";
					this.form.importe = "";
					this.$refs.form.resetValidation();
				}
			},
			setPolizaEnTramite() {
				this.nroPolizaRequerido = !this.nroPolizaRequerido;
				this.hintNroPoliza = this.nroPolizaRequerido ? "" : "No requerido";
			},
			next() {
				//if (this.$refs.form.validate() && this.primeravez) {
				if (this.$refs.form.validate()) {
					this.form.guardaHabitual.switchRegistrar =
						this.$refs.guardaHabitual.switchRegistrar;
					//console.log(this.$refs.guardaHabitual.switchRegistrar);
					this.$emit("send-message", "6", "5", this.form);
				} else {
					this.snackbar = true;
					this.text = "Verifique los datos ingresados y/o campos obligatorios";
					this.color = "error";
				}
			},
			setDatosPrueba() {},
			resetItem() {
				/*this.form.guardaHabitual.calle ='';
							      this.form.guardaHabitual.numero ='';
							      this.form.guardaHabitual.piso ='';
							      this.form.guardaHabitual.depto ='';
							      this.form.guardaHabitual.provincia ='';
							      this.form.guardaHabitual.localidad ='';
							      this.form.guardaHabitual.barrio ='';
							      this.form.guardaHabitual.cp ='';
							      this.form.guardaHabitual.partido ='';*/
				this.$refs.form.resetValidation();
			},
		},
		computed: {},
		created() {
			//this.setDatosPrueba();
			//this.$getListForSelect("aseguradoras");
			this.$getData(["aseguradoras"]);
			this.valid = true;
		},

		watch: {},
		mounted() {
			if (this.seguro && Object.keys(this.seguro).length) {
				this.form = this.seguro;
				if (this.form.aseguradora_id) {
					this.seguroDisable = true;
					this.setPolizaEnTramite();
				}
				if (
					this.form.guardaHabitual.calle != null &&
					this.form.guardaHabitual.calle != ""
				) {
					//console.log(this.form.guardaHabitual.calle);
					this.$refs.guardaHabitual.switchRegistrar = true;
				} else {
					this.$refs.guardaHabitual.switchRegistrar = false;
					this.$refs.guardaHabitual.setRegistrar();
				}

				this.conFormulario02();
			}

			/*if (
																																							this.$root.$children[0].$children[0].$children[0].$children[0].$vnode
																																								.componentOptions.tag == "dashboard-core-app-bar"
																																						)
																																							this.$root.$children[0].$children[0].$children[0].$children[0].setDrawer(
																																								false
																																							);
																																						if (
																																							this.$root.$children[0].$children[0].$children[0].$children[1].$vnode
																																								.componentOptions.tag == "dashboard-core-app-bar"
																																						)
																																							this.$root.$children[0].$children[0].$children[0].$children[1].setDrawer(
																																								false
																																							);

																																						console.log("Componente Datos Tramite creado");*/
		},
	};
</script>
